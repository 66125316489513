
import { ConstructionSharp } from "@mui/icons-material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";


      
  const  series = [{
      name: 'Buy Order',
      data: [31, 40, 28, 51, 42, 109, 100]
    }, {
      name: 'Sell Order',
      data: [11, 32, 45, 32, 34, 52, 41]
    }]



    const options = {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    }
  
  
 





function Charts2() {
    // const [first, setfirst] = useState(options) 
    // const [series, setseries] = useState(series) 
    
      
    
    

  return (
    <div style={{height:"100%"}}>
          <div id="chart" style={{height:"100%", border:"1px solid #fafafa"}}>
  <ReactApexChart options={options} series={series} type="area" ight={350} height="100%" />
</div>
    </div>
  )
}

export default Charts2