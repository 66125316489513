

import React, { createContext, useEffect, useState,} from "react";
import axios from "axios";

export const dataContex = createContext();



function DataContex({ children }) {
  const [market, setMarket] = useState();
  const [trending, settrending] = useState([]);
  const [aptos, setaptos] = useState([]);
  const [ai, setai] = useState([]);
  const [bsc, setbsc] = useState([]);
  const [cardano, setcardano] = useState([]);
  const [exchanges, setexchanges] = useState([]);
  const [defi, setdefi] = useState([]);
  const [derivatives, setderivatives] = useState([]);
  const [entertainment, setentertainment] = useState([]);
  const [ethereumecosystem, setethereumecosystem] = useState([]);
  const [gaming, setgaming] = useState([]);
  const [nft, setnft] = useState([]);
  const [realestate, setrealestate] = useState([]);
  const [dot, setdot] = useState([]);
  const [predictionmarket, setpredictionmarket] = useState([]);
  const [syntheticasset, setsyntheticassest] = useState([]);


  const proxyurl ="https://cors-anywhere.herokuapp.com/"

  useEffect(() => {

    
      fetchMarket();

    // fetchTrending()
    // fetchAptos()
    // fetchAI()
    // fetchBSC()
    // fetchCardano()
    // fetchExchanges()
    // fetchDerivatives()
    // fetchEntertainment()
    // fetchGaming()
    // fetchETH()
    // fetchNFT()
    // fetchRealEstate()
    // fetchDot()
    // fetchPrediction()
    // fetchSyntheticAsset()
    // fetchDefi()
    
  
   
   
  }, []);

 
      

  const fetchMarket = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=4&page=1&sparkline=false")
        .then((response)=> {
          // handle success
          if(response != null){
            const data = response.data;
         
            setMarket(data)
            console.log(response.data)
          
          }
    
       
        })
        
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      

     
    } catch (err) {
      console.log(err);
    }

   
  };

  const fetchTrending = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/search/trending")
        .then(function (response) {
          // handle success
          const data = response.data.coins;
         settrending(data)
         console.log(data)
         
        })
       
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      
     
    } catch (err) {
      console.log(err);
    }
  };


  const fetchAptos = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=aptos-ecosystem&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
          setaptos(response.data)
          console.log(data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

     
    
     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAI = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=artificial-intelligence&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setai(response.data)
         console.log(response.data)
         
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      

     
    } catch (err) {
      console.log(err);
    }
  };


  const fetchBSC = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=binance-smart-chain&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setbsc(response.data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       
     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCardano = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=cardano-ecosystem&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;;
         setcardano(data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

     

     
    } catch (err) {
      console.log(err);
    }
  };


  const fetchExchanges = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=centralized-exchange-token-cex&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setexchanges(data)
         console.log(response.data)
      
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDerivatives = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=decentralized-derivatives&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setderivatives(response.data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDefi = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=decentralized-finance-defi&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setdefi(response.data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
     
     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEntertainment = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=entertainment&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setentertainment(response.data)
         console.log(response.data)
         
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       
    } catch (err) {
      console.log(err);
    }
  };


  const fetchETH = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=ethereum-ecosystem&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
          setethereumecosystem(response.data)
          console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       
    } catch (err) {
      console.log(err);
    }
  };


  const fetchGaming = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=gambling&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setgaming(response.data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       

    } catch (err) {
      console.log(err);
    }
  };

  const fetchNFT = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=non-fungible-tokens-nft&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
         setnft(response.data)
         console.log(response.data)
         
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRealEstate = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=real-estate&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
          setrealestate(response.data)
          console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
      
     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDot = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=dot-ecosystem&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;;
         setdot(response.data)
         console.log(response.data)
        
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
     
     
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPrediction = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=prediction-markets&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
          setpredictionmarket(response.data)
          console.log(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

       
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSyntheticAsset = async () => {
  
    try {
      await axios
        .get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&category=synthetic-assets&order=market_cap_desc&per_page=10&page=1&sparkline=false")
        .then(function (response) {
          // handle success
          const data = response.data;
          setsyntheticassest(response.data)
          console.log(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

   
    } catch (err) {
      console.log(err);
    }
  };




  return (
    <dataContex.Provider
      value={{
  //       market,
  //       trending,
  //       aptos,
  //     ai,
  //     bsc,
  //  cardano,
  //  exchanges,
  //  defi,
  //  derivatives,
  //  entertainment,
  //  ethereumecosystem,
  //  gaming, 
  //  nft,
  //  realestate,
  //  dot,
  //  predictionmarket,
  //  syntheticasset
    
   
      }}
    >
      {children}
    </dataContex.Provider>
  );
}

export default DataContex;
