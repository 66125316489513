import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers5/react'
import { ethers } from 'ethers'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from './Button';
import { Box, Typography } from '@mui/material';
import "../App.css"
import kulpay from "../images/kulpay.png"

function Navbar() {
    const { address, chainId, isConnected } = useWeb3ModalAccount()
  const [mobile, setmobile] = useState(false);







  return (
  <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"row", paddingRight:"5%", height:"70px", backgroundColor:'#1C2131'}}>
    <Box sx={{display:"flex", alignItems:"center", flexDirection:"row", padding:"10px",}}>
    <img  src={kulpay }  style={{width:"40px", height:"40px"}}/>
    <Typography sx={{color:"#fff", fontWeight:"700", display:{xs:"none", sm:"block"}}}>KULPAY TOKEN</Typography>
    </Box>
 
    {!address && <Button />}

{address && <w3m-account-button />}
  </Box> 
  )
}

export default Navbar