import React, { useState } from "react";
import { useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/ethers5/react'


function Button() {

  const { open, close } = useWeb3Modal()




 
  return <button style={{  backgroundColor:"#FFBB00", fontWeight:"700" , borderRadius:"10px",padding:"10px",fontSize:"1rem" }} 
  onClick={()=>{open()}}
  >CONNECT WALLET</button>
}

export default Button