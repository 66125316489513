import React from 'react'
import Ticker from 'react-ticker'
import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { dataContex } from '../pages/DataContex'

function Tickers (){

    const {
  
        market, trending,
    
      
      } = useContext(dataContex)

      if( !market){
        return null
      }

      console.log(market)

return(
    <Ticker>
    {({ index }) => (
        <>
            <Box sx={{backgroundColor:"#f2f3f5", height:"5vh", width:"100%", margin:"0px 0px", border:"3px solid #fafafa", display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center" }}>
<Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center"}}>
  
  {trending.map(()=>{

    return(  <Box spacing={2} sx={{display:"flex", flexDirection:"row", justifyContent:"space-around", alignItems:"center", width:"150px", borderRight:"1px solid #111"}}>
    <Typography sx={{fontSize:"12px"}}>BTC/USDT</Typography>
    <Typography sx={{fontSize:"12px", color:"green"}}>3%</Typography>
    <Typography sx={{fontSize:"12px"}}>$2500</Typography>
    </Box>)
  })}


</Box>
</Box>
        </>
    )}
</Ticker>
)




}
export default Tickers 