export default function timestampToDateTime(timestamp) {
    // Convert Unix timestamp to milliseconds if it's in seconds
    const milliseconds = timestamp * 1000;
  
    // Create a new Date object
    const dateObject = new Date(milliseconds);
  
    // Use the Date object methods to get the different parts of the date
    const year = dateObject.getFullYear();
    const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + dateObject.getDate()).slice(-2);
    const hours = ('0' + dateObject.getHours()).slice(-2);
    const minutes = ('0' + dateObject.getMinutes()).slice(-2);
    const seconds = ('0' + dateObject.getSeconds()).slice(-2);
  
    // Format the date and time
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    return formattedDateTime;
  }
  

  