import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import Navbar from '../src/components/Navbar';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';



import "./index.css";


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import DataContex from './pages/DataContex';

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '4ae8ebd438710003707a481f1859563a'

// 2. Set chains
// const mainnet = {
//   chainId: 80001,
//   name: 'Mumbai',
//   currency: 'MATIC',
//   explorerUrl: 'https://mumbai.polygonscan.com',
//   rpcUrl: 'https://polygon-mumbai-bor-rpc.publicnode.com'
// }


// 2. Set chains
const mainnet = {
  chainId: 56,
  name: 'Smart Chain',
  currency: 'BSC',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed4.ninicoin.io'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId
})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
   
   
   < DataContex>


  
    <div>
    <Navbar/>
    <App /> 
    </div>

   
    </DataContex>


  
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();